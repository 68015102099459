<template>
  <div class="right-header-box">
    <div class="header-left">
      <div class="tool-item" @click="$store.dispatch('setting/toggleMenuCollapse')">
        <i :class="isCollapse ? 'el-icon-_unfold' : 'el-icon-_fold'"/>
      </div>
      <div class="tool-item">
        <i class="el-icon-refresh-right" @click="$emit('reloadPage')"/>
      </div>
      <el-breadcrumb separator="/" style="margin-left: 12px">
        <el-breadcrumb-item>{{$t('layout.home')}}</el-breadcrumb-item>
        <template v-for="title in titles">
          <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
        </template>
      </el-breadcrumb>
    </div>
    <div class="header-right">
      <div class="tool-item" @click="$store.dispatch('setting/toggleFullscreen')">
        <i :class="isFullscreen ? 'el-icon-_screen-restore' : 'el-icon-_screen-full'"/>
      </div>
      <div class="tool-item">
        <ChangeLang/>
      </div>
      <!-- <div class="tool-item">
        <Notice/>
      </div> -->
      <div class="tool-item">
        <el-dropdown @command="handleCommand">
          <div class="ele-admin-header-avatar">
            <el-avatar :src="logoUrl"/>
            <span class="hidden-xs-only">{{ userInfo.name }}</span>
            <i class="el-icon-arrow-down hidden-xs-only"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="profile" icon="el-icon-user">{{$t('layout.profile')}}</el-dropdown-item>
            <el-dropdown-item command="changePassword" icon="el-icon-key">{{$t('layout.changePassword')}}</el-dropdown-item>
            <el-dropdown-item command="logout" icon="el-icon-switch-button" divided>{{$t('layout.logout')}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <ChangePasswordDialog ref="changePassword"/>
    <ProfileInfoDialog ref="profileInfo"/>
  </div>
</template>
<script>
import {logoutSuccess} from "@/utils/func";
import ChangePasswordDialog from "./ChangePasswordDialog";
import ProfileInfoDialog from "./ProfileInfoDialog";
import ChangeLang from "@/components/ChangeLang/ChangeLang";
import Notice from "./Notice";
import logoUrl from '@/assets/logo.png'

export default {
  name: "RightHeader",
  components: {Notice, ChangeLang, ProfileInfoDialog, ChangePasswordDialog},
  
  data() {
    return {
      logoUrl
    }
  },
  
  computed: {
    isCollapse() {
      return this.$store.state.setting.menuCollapse
    },
    isFullscreen() {
      return this.$store.state.setting.fullscreen
    },
    userInfo() {
      return this.$store.state.user.info || {}
    },
    titles() {
      if (this.$i18n.locale === 'en') {
        return this.$route.matched.slice(1).filter(item => !!item.meta).map(item => item.meta.enTitle)
      }
      return this.$route.matched.slice(1).filter(item => !!item.meta).map(item => item.meta.title)
    }
  },
  
  methods: {
    handleCommand(cmd) {
      if (cmd === 'logout') {
        logoutSuccess()
      }
      
      if (cmd === 'changePassword') {
        this.$refs.changePassword.start()
      }
      
      if (cmd === 'profile') {
        this.$refs.profileInfo.start()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/style/var";

.right-header-box {
  height: 60px;
  background: #fff;
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: $--border-base;
  
  .header-left {
    padding-left: 12px;
    display: flex;
    align-items: center;
  }
  
  .header-right {
    display: flex;
    align-items: center;
  }
  
  .tool-item {
    padding: 0 12px;
    height: 60px;
    cursor: pointer;
    font-size: 18px;
    color: #606266;
    font-weight: normal;
    display: flex;
    align-items: center;
    
    &:hover {
      background-color: rgba(0, 0, 0, .025);
    }
  }
  
  .ele-admin-header-avatar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    font-size: 14px;
  
    .el-avatar {
      width: 28px;
      height: 28px;
    }
  
    .el-avatar + span {
      margin-left: 4px;
    }
  
    .el-icon-arrow-down {
      font-size: 12px;
      margin-left: 4px;
      font-weight: bold;
    }
  }
}

.el-dropdown-link {
  color: $--color-primary;
  cursor: pointer;
}
</style>